.section {
  padding-bottom: 128px;
  transition: all 0.3s;

  &.section--last {
    padding-bottom: 0;
  }

  &.section--first {
    margin-top: 128px;
  }

  &.section--left-content {
    .section__title {
      text-align: left;
    }

    .section__description {
      text-align: left;
      margin: 0;
      max-width: 1010px;
    }
  }

  .section__title {
    margin-bottom: 50px;
    text-align: center;
  }

  .section__description {
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
  }

  hr {
    margin-top: 128px;
    margin-right: 15px;
    margin-left: 15px;
    transition: all 0.3s;
  }

  .section__hr-light {
    margin-top: 130px;
    margin-right: 15px;
    margin-left: 15px;
    transition: all 0.3s;
    background-color: #ffffff;
    color: #ffffff;
  }

  &.section--light {
    background-color: $color-gray-4;
    padding-top: 128px;
  }

  &.section--dark {
    background-color: $color-gray-2;
    padding-top: 128px;
    margin-bottom: 128px;

    h3 {
      color: #ffffff;
    }
  }

  &.section--half {
    position: relative;
    padding: 0;

    &.section--bottom-space {
      padding-bottom: 128px;
      margin-bottom: 0;
    }

    &::after {
      content: "";
      height: 50%;
      position: absolute;
      bottom: 0;
      background-color: $color-gray-4;
      z-index: -1;
      width: 100%;
    }
  }

  .section__img {
    width: 100%;
  }

  &.section--bottom-space {
    margin-bottom: 128px;
  }

  &.section--top-space {
    margin-top: 250px;
  }

  @media screen and (max-width: 1200px) {
    padding-bottom: 105px;

    &.section--first {
      margin-top: 105px;
    }

    &.section--light {
      padding-top: 105px;
    }

    &.section--dark {
      padding-top: 105px;
      margin-bottom: 105px;
    }

    &.section--bottom-space {
      margin-bottom: 105px;
    }

    &.section--half {

      &.section--bottom-space {
        padding-bottom: 105px;
      }
    }

    hr {
      margin-top: 105px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 70px;

    &.section--first {
      margin-top: 70px;
    }

    &.section--light {
      padding-top: 70px;
    }

    &.section--dark {
      padding-top: 70px;
      margin-bottom: 70px;
    }

    &.section--bottom-space {
      margin-bottom: 70px;
    }

    &.section--top-space {
      margin-top: 158px;
    }

    .section__title {
      margin-bottom: 40px;
    }

    .section__img {
      width: auto;
      height: 260px;
      object-fit: cover;
    }

    &.section--half {

      &.section--bottom-space {
        padding-bottom: 70px;
      }
    }

    hr {
      margin-top: 70px;
    }
  }
}