.popular {
  display: flex;
  margin-top: 32px;
  cursor: pointer;

  .popular__img {
    height: 64px;
    width: 64px;
    min-width: 64px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .popular__title {
    margin: auto 0 auto 16px;
    font-size: 14px;
    line-height: 1.7;
    transition: all 0.3s;
    color: $color-text-title;
    font-weight: 500;
  }

  &:hover {

    .popular__title {
      color: $color-accent;
    }
  }
}