.sidebar {

  &.sidebar--fixed {
    position: fixed;
    top: 95px;
    transform: translateY(0);
    transition: transform 0.3s;
  }

  .sidebar__list {
    display: grid;
    padding: 0;
  }

  .sidebar__item {
    padding: 22px 33px;
    border-radius: 8px;
    cursor: pointer;

    a {
      color: $color-text-main;
      font-size: 24px;
    }

    &.active {
      background-color: $color-gray-4;
    }
  }

  &.sidebar--bottom {
    bottom: 64px;
    position: absolute;
    top: auto;
  }
}