.native {
  text-align: center;

  .native__btns {
    margin-top: 70px;
    margin-bottom: 60px;
    text-align: center;
  }

  .native__img {
    margin-bottom: -139px;
  }

  @media screen and (max-width: 1200px) {

    .native__img {
      margin-bottom: -116px;
    }
  }

  @media screen and (max-width: 768px) {

    .native__btn-first {
      margin-bottom: 60px;
    }

    .native__img {
      margin-bottom: -81px;
    }
  }
}