.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
  text-align: center;
  z-index: 9999;
  transition: all 0.6s ease;
  padding: 0;
  background-color: #ffffff;
  transform: translate(0, -100%);

  &.mobile-menu--active {
    transform: translate(0, 0);
  }

  .mobile-menu__wrapper {
    margin-top: 135px;
    transition: all 0.3s;
    overflow-y: auto;
    max-height: calc(100vh - 135px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .mobile-menu__ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    transition: all 0.3s;
  }

  .mobile-menu__ul--collapsed {
    overflow: hidden;
    padding-bottom: 0;

    li {
      height: 0;
      padding-bottom: 0;
      transition: height 0.3s ease-out 0s, padding-bottom 0.3s ease-out 0s, opacity 0.6s 0.3s;
      opacity: 0;
    }
  }

  .mobile-menu__li-collapse--close + .mobile-menu__ul--collapsed {
    transition: padding-bottom 0.6s ease-out 0.3s;

    li {
      transition: height 0.6s ease-out 0.3s, padding-bottom 0.6s ease-out 0.3s, opacity 0.3s 0s;
    }
  }

  .mobile-menu__li-collapse--active + .mobile-menu__ul--collapsed {
    padding-bottom: 15px;
    transition: padding-bottom 0.3s ease-out 0s;

    li {
      opacity: 1;
      height: 45px;
      padding-bottom: 25px;
    }
  }

  .mobile-menu__li {
    padding-bottom: 25px;
    font-size: $font-size-btn;
    line-height: $line-height-btn;
  }

  .mobile-menu__close {
    color: $color-text-main;
    transition: all 0.3s;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 46px;
    top: 75px;
    font-size: 20px;
    line-height: 20px;
    padding: 0;
    width: 20px;
    height: 20px;

    &:hover {
      color: $color-accent;
    }

    &:focus {
      outline: none;
    }
  }

  .mobile-menu__logo {
    position: absolute;
    transition: all 0.3s;
    top: 60px;
    left: 46px;
  }

  &.mobile-menu--scroll {

    .mobile-menu__close {
      top: 40px;
    }

    .mobile-menu__logo {
      top: 25px;
    }

    .mobile-menu__wrapper {
      margin-top: 100px;
      max-height: calc(100vh - 100px);
    }
  }

  @media screen and (max-width: 520px) {
    .mobile-menu__logo {
      left: 16px;
    }

    .mobile-menu__close {
      right: 16px;
    }
  }

  @media screen and (max-width: 480px) {

    .menu__logo-img {
      width: 32px;
      height: 32px;
    }

    .mobile-menu__logo {
      top: 69px;
    }

    &.mobile-menu--scroll {
      .mobile-menu__close {
        top: 22px;
      }

      .mobile-menu__logo {
        top: 16px;
      }
    }
  }
}