.link {
  transition: all 0.3s;
  cursor: pointer;

  &.link--gray {
    color: $color-text-main;

    &:hover {
      color: $color-accent;
    }

    &.link--gray-active {
      color: $color-accent-active;
      position: relative;
    }

    &.link--gray-active-underline {
      position: relative;

      &:after {
        content: "";
        width: 100%;
        position: absolute;
        height: 3px;
        background-color: $color-accent;
        bottom: -17px;
        left: 0;
      }
    }
  }

  &.link--dark-gray {
    color: $color-text-title;

    &:hover {
      color: $color-accent;
    }

    &.link--gray-active {
      color: $color-accent-active;
      position: relative;
    }
  }

  &.link--accent {
    color: $color-accent;

    &:hover {
      color: $color-accent-hover;
      text-decoration: underline;
    }

    &:active {
      color: $color-accent-active;
      text-decoration: underline;
    }
  }

  &.link--accent-bold {
    color: $color-accent;
    font-weight: 800;

    &:hover {
      color: $color-accent;
    }

    &:active {
      color: $color-accent-active;
    }
  }
}