h2 {
  font-size: $font-size-h1;
  line-height: $line-height-h1;
  font-weight: 200;
  color: $color-text-title;
  margin: 0;
}

h3 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
  font-weight: 200;
  color: $color-text-title;
  margin: 0;
}

h4 {
  font-size: $font-size-h3;
  line-height: $line-height-h3;
  font-weight: 400;
  color: $color-text-title;
  margin: 0;
}

h5{
  font-size: $font-size-t1;
  line-height: $line-height-t1;
  font-weight: 400;
  color: $color-text-title;
  margin: 0;
}


h6{
  font-size: $font-size-t2;
  line-height: $line-height-t2;
  font-weight: 600;
  margin: 0;
}

@media screen and (max-width: 480px) {

  h2 {
    font-size: $font-size-h2;
    line-height: $line-height-h2;
  }

  h3 {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
  }

  h4 {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
  }
}