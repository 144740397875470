.privacy {

  .privacy__content {
    max-width: 1010px;
  }

  .privacy__chapter {
    margin-bottom: 76px;
  }

  .privacy__chapter-title {
    margin-bottom: 56px;
  }

  .privacy__chapter-subtitle {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .privacy__chapter-description--bold {
    font-weight: 800;
  }

  .privacy__chapter-list-title {
    padding-left: 40px;
  }

  .privacy__chapter-list {
    list-style: disc;
  }
}