.download {
  text-align: center;

  .download__title {
    font-weight: 800;
  }

  .download__btns {
    margin-top: 70px;
    margin-bottom: 60px;
    text-align: center;
  }

  @media screen and (max-width: 768px) {

    .download__btn-first {
      margin-bottom: 60px;
    }
  }
}